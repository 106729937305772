// React stuff
import React from "react";
import { Link } from 'react-router-dom';

// Components
import { PersonalContainer, PersonalImg, PersonalDescContainer, PersonalDescHeader, PersonalDescText } from '../Components/Personal.Styles.js';
import { MusicContainer, MusicImg, MusicDescContainer, MusicDescHeader, MusicDescText } from '../Components/Music.Styles.js';
import { MemoriesContainer, MemoriesImg, MemoriesDescContainer, MemoriesDescHeader, MemoriesDescText, ImageDiv } from '../Components/Memories.Styles.js';

// Assets and Images
import mikeImg from "../Assets/mikeImgs/homeImg2.jpg";
import p1 from '../Assets/personalAssets/p1.jpg';
import p2 from '../Assets/personalAssets/p2.jpg';
import p3 from '../Assets/personalAssets/p3.jpg';
import p4 from '../Assets/personalAssets/p4.jpg';
import p5 from '../Assets/personalAssets/p5.jpg';
import p6 from '../Assets/personalAssets/p6.jpg';
import albumJPG from '../Assets/personalAssets/album.jpg';

// AOS animations
import AOS from 'aos';
import 'aos/dist/aos.css';

const Personal = () => {

    AOS.init();

	return (
		<div>

			<PersonalContainer>
			 	<PersonalImg src={mikeImg} data-aos="fade-up" data-aos-duration="600"/>
			 	<PersonalDescContainer data-aos="fade-up" data-aos-duration="600" data-aos-delay="300">
			 		<PersonalDescHeader>
			 			{`// about me`}
			 		</PersonalDescHeader>

			 		<PersonalDescText>
			 			i was born and raised <Link to="https://en.wikipedia.org/wiki/Connecticut" className="textLink"> here.</Link> outside of school, i like to play basketball and listen to music (more below!). 
			 			i grew up playing soccer, but these days i spend time catching up on celtics or <Link to="https://en.wikipedia.org/wiki/Super_Bowl_LI" className="textLink">patriots</Link> games.
			 		</PersonalDescText>

			 		<PersonalDescText>
			 			although i don't game regularly anymore, i enjoy queueing up some <Link to="https://liquipedia.net/valorant/Omen" className="textLink"> valorant</Link> and
						 <Link to="https://en.wikipedia.org/wiki/ELEAGUE_Major:_Boston_2018" className="textLink"> counter-strike</Link> here and there. when i'm at the gym, i love warming up before my lift 
						with some <Link to="https://en.wikipedia.org/wiki/Jayson_Tatum" className="textLink"> basketball. </Link>  every six months, i find myself running back
						to my <Link to="https://en.wikipedia.org/wiki/Brian_May" className="textLink"> guitar.</Link>
			 		</PersonalDescText>

			 		<PersonalDescText>
			 			i am obsessed with <Link to="https://www.bu.edu/data-sciences-center/" className="textLink"> whiteboards!</Link> i'm addicted to walking around boston listening
						to <Link to="https://www.apple.com/airpods-pro/?campaign=true" className="textLink">music.</Link> i hate eggs. i spend a lot of time drawing spongebob on random whiteboards (and spare exams). my favorite 
			 			food is <Link to="https://en.wikipedia.org/wiki/Smoke%27s_Poutinerie" className="textLink"> poutine</Link> and diet coke is delicious.
			 		</PersonalDescText>
			 	</PersonalDescContainer>
			</PersonalContainer>

			<MusicContainer>
				<MusicDescContainer data-aos="fade-down" data-aos-duration="600" data-aos-delay="200">
					<MusicDescHeader>
						{`// music!`}
					</MusicDescHeader>

					<MusicDescText>
						depicted on the right is one of the records i own! i also love <Link to="https://open.spotify.com/artist/4Z8W4fKeB5YxbusRsdQVPb" className="textLink"> this band</Link> and <Link to="https://open.spotify.com/artist/6Q192DXotxtaysaqNPy5yR?si=93675e8222f04489" className="textLink"> this artist.</Link> you can check out the world's best music taste by clicking <Link to="https://open.spotify.com/user/i6y2vifk8jybzcmyvia4ghjbx?si=b78deb42af2945b0" className="textLink"> here.</Link>
					</MusicDescText>
					
					<MusicDescText>
						<Link to="https://open.spotify.com/artist/6U3ybJ9UHNKEdsH7ktGBZ7?si=e8609171ab8046ef" className="textLink"> this</Link> is an artist that i've discovered recently and really like. <Link to="https://open.spotify.com/track/2khjSwh01eCWBstZo9rDGn?si=3b857c4c86764cd2" className="textLink"> this</Link> is a song that i've found myself listening to nonstop these days. <Link to="https://open.spotify.com/track/5mIOsPuQdXchVY0jB5NO9Q?si=4fe5b377e3bb4f7a" className="textLink"> this </Link> is a song that i've been listening to forever.
					</MusicDescText>

					<MusicDescText>
						<Link to="https://open.spotify.com/playlist/34fYgY7gO0tPcXuRNAxzR6?si=a047c0200d2d4926" className="textLink"> here</Link> is a playlist i made for doing schoolwork/programming! i listened to it while making this site (and writing these words).
					</MusicDescText>

					</MusicDescContainer>
				<MusicImg src={albumJPG} data-aos="fade-up" data-aos-duration="600" data-aos-delay="300"/>
			</MusicContainer>


			<MemoriesContainer id="mem">
					<div className="container-md">

						<MemoriesDescContainer>
							<MemoriesDescHeader>
								{`// pictures!`}
							</MemoriesDescHeader>

							<MemoriesDescText>
								here are some pictures that i think are super cool!
							</MemoriesDescText>
						</MemoriesDescContainer>

						<div className="row">

							<ImageDiv className="col-md-4">
								<MemoriesImg src={p1} />
								<MemoriesImg src={p4} />
							</ImageDiv>

							<ImageDiv className="col-md-4">
								<MemoriesImg src={p2} />
								<MemoriesImg src={p5} />
							</ImageDiv>

							<ImageDiv className="col-md-4">
								<MemoriesImg src={p3} />
								<MemoriesImg src={p6} />
							</ImageDiv>
						</div>
					</div>
			</MemoriesContainer>
		</div>
	)
}

export default Personal;